<template>
    <el-form
        ref="cakeshopForm"
        :model="cakeshop"
        :rules="rules"
        size="medium"
        :status-icon="true"
        :inline-message="true"
        :hide-required-asterisk="true"
        label-position="top"
        @submit.prevent>
        <el-row :gutter="20">

            <el-col :span="9">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            DESCRIPCIÓN GENERAL
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="name">
                            <template slot="label">
                                Nombre*
                            </template>
                            <el-input
                                v-model="cakeshop.name"
                                placeholder="Ej. La Tartiere"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item prop="description">
                            <template slot="label">
                                Descripción*
                            </template>
                            <el-input
                                v-model="cakeshop.description"
                                type="textarea"
                                :autosize="{ minRows: 3}"
                                maxlength="256"
                                placeholder="Máx 256 caracteres"
                                class="max-width">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-show="role === 'ADMIN'">
                    <el-col>
                        <el-form-item prop="rate">
                            <template slot="label">
                                Puntuación
                            </template>
                            <el-input
                                v-model="cakeshop.rate"
                                placeholder="Ej. 4,7"
                                class="max-width-small"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-show="role === 'BAKER'" class="mt-4">
                    <el-col>
                        <i class="el-icon-info icon-gray"></i>
                        Esta columna contiene la información que aparecerá en la aplicación de Wecake sobre tu pastelería
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="6">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            RESPONSABLE PASTELERÍA
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="managerName">
                            <template slot="label">
                                Nombre*
                            </template>
                            <el-input
                                v-model="cakeshop.managerName"
                                placeholder="Ej. Sr. Tartiere"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item prop="managerPhone">
                            <template slot="label">
                                Móvil*
                            </template>
                            <el-input
                                v-model.number="cakeshop.managerPhone"
                                placeholder="Ej. 643879609"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div v-if="showAddress" class="mt-4">

                    <el-row>
                        <el-col>
                            <div class="section-title">
                                DIRECCIÓN PRINCIPAL
                            </div>
                        </el-col>
                    </el-row>

                    <el-row class="mt-2">
                        <el-col>
                            <el-form-item prop="address">
                                <template slot="label">
                                    Dirección*
                                </template>
                                <el-input
                                    v-model="cakeshop.address"
                                    placeholder="Ej. Calle Delicias, 3"
                                    class="max-width"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col>
                            <el-form-item prop="postalCode">
                                <template slot="label">
                                    Código Postal*
                                </template>
                                <el-input
                                    v-model.number="cakeshop.postalCode"
                                    placeholder="Ej. 28004"
                                    class="max-width"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col>
                            <el-form-item prop="city">
                                <template slot="label">
                                    Ciudad*
                                </template>
                                <city-selector v-model="cakeshop.city"></city-selector>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </div>

            </el-col>

            <el-col :span="9">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            IMAGEN DESTACADA
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-3">
                    <el-col>
                        <el-form-item prop="image">
                            <image-upload-base64
                                v-model="cakeshop.image"
                                :do-loading="imageLoading"
                                :show-required-error="showRequiredImageError"
                                @input="validateImageField('image')"></image-upload-base64>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-5">
                    <el-col>
                        <div class="legend">*Obligatorio</div>
                    </el-col>
                </el-row>
            </el-col>

        </el-row>
    </el-form>
</template>

<script>
    import ImageUploadBase64 from '../ImageUploadBase64';
    import CitySelector from '../CitySelector';
    import {mapGetters} from 'vuex';

    export default {
        name: "CakeshopForm",
        components: {
            ImageUploadBase64,
            CitySelector
        },
        props: {
            doFormSubmit: {
                type: Boolean
            },
            showAddress:{
                type: Boolean,
                default: false
            },
            initialCakeshop: {
                type: Object,
                default: function () {
                    return {
                        name: null,
                        description: null,
                        rate: null,
                        managerName: null,
                        managerPhone: null,
                    }
                }
            },
            initialImage: {
                type: String
            },
            imageLoading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            let rateValidator = (rule, value, callback) => {
                if (value) {
                    setTimeout(() => {
                        if (!/^\d+([.,]\d)?$/.test(value)) {
                            callback(new Error('No es un valor numérico'));
                        } else if (value > 5 || value < 0) {
                            callback(new Error('Valor entre 0 y 5'));
                        } else {
                            callback();
                        }
                    }, 200);
                } else {
                    return callback();
                }
            };

            let postalCode = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('Campo obligatorio'));
                }
                setTimeout(() => {
                    if (!Number.isInteger(parseInt(value)) || value < 0) {
                        callback(new Error('No es un valor numérico'));
                    } else {
                        callback();
                    }
                }, 200);
            };

            let requiredPhoneValidator = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('Campo obligatorio'));
                }
                setTimeout(() => {
                    if (!Number.isInteger(value)) {
                        callback(new Error('No es un valor numérico'));
                    } else if (!/^([6-9])\d{8}$/.test(value)) {
                        callback(new Error('Número no válido'));
                    } else {
                        callback();
                    }
                }, 200);
            };

            let imageValidator = (rule, value, callback) => {
                if (value) {
                    return callback();
                } else {
                    return callback(new Error('Campo obligatorio'));
                }
            };

            return {
                cakeshop: {
                    name: null,
                    description: null,
                    rate: null,
                    managerName: null,
                    managerPhone: null,
                    address: null,
                    postalCode: null,
                    city: null,
                    image: null
                },
                rules: {
                    name: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                        {min: 3, message: 'Longitud mínima de 3 caracteres', trigger: 'blur'}
                    ],
                    description: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                        {min: 3, max: 256, message: 'Longitud entre 3 y 256 caracteres', trigger: 'blur'}
                    ],
                    rate: [
                        {validator: rateValidator, trigger: 'blur'}
                    ],
                    managerName: [
                        {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                        {min: 3, message: 'Longitud mínima de 3 caracteres', trigger: 'blur'}
                    ],
                    managerPhone: [
                        {validator: requiredPhoneValidator, trigger: 'blur'}
                    ],
                    city: [
                        {required: this.showAddress, message: 'Campo obligatorio', trigger: 'change'}
                    ],
                    address: [
                        {required: this.showAddress, message: 'Campo obligatorio', trigger: 'blur'},
                        {min: 5, message: 'Longitud mínima de 5 caracteres', trigger: 'blur'}
                    ],
                    postalCode: [
                        {validator: this.showAddress ? postalCode : null, trigger: 'blur'}
                    ],
                    image: [
                        {validator: imageValidator}
                    ]
                },
                showRequiredImageError: false
            }
        },
        computed: {
            ...mapGetters([
                'role',
            ]),
        },
        created() {
            this.fillFormWithInitialValues();
            this.cakeshop.image = this.initialImage;
        },
        methods: {
            fillFormWithInitialValues() {
                this.cakeshop = Object.assign({}, this.cakeshop, this.initialCakeshop);
            },
            validateImageField(prop) {
                this.showRequiredImageError = false;
                this.$refs['cakeshopForm'].validateField(prop, (message) => {
                    if (message) {
                        this.showRequiredImageError = true;
                    }
                });
            },
            submitForm() {
                this.$refs['cakeshopForm'].validate((valid) => {
                    if (valid) {
                        this.$emit('submit', this.cakeshop);
                    } else {
                        this.$emit('not-valid');
                        return false;
                    }
                });
            }
        },
        watch: {
            doFormSubmit: function () {
                if (this.doFormSubmit) {
                    this.submitForm();
                }
            },
            initialCakeshop: function () {
                this.fillFormWithInitialValues();
            },
            initialImage: function () {
                this.cakeshop.image = this.initialImage;
            },
            'cakeshop.description': function () {
                if (!!this.cakeshop.description && this.cakeshop.description.length > 256) {
                    this.cakeshop.description = this.cakeshop.description.substring(0, 256);
                }
            }
        }
    }
</script>

<style scoped>
    .el-form-item {
        margin-bottom: 0.5rem; /* equal to 'mb-2' class */
    }

    >>> label {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .legend {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #5D5039;
    }
</style>
