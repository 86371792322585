<template>
    <el-row>
        <el-col>

            <el-row class="mb-4">
                <el-col :span="24" class="text-center">
                    <div class="view-subtitle">{{title}}</div>
                </el-col>
            </el-row>

            <cake-prices-form
                :do-form-submit="doFormSubmit"
                :initial-cake-customizations="initialCakeCustomizations"
                :initial-cake-fillings="initialCakeFillings"
                :initial-cake-flours="initialCakeFlours"
                :initial-cake-toppings="initialCakeToppings"
                :initial-cake-sweeteners="initialCakeSweeteners"
                :purchasable="purchasable"
                @submit="doAction"
                @not-valid="resetAction"></cake-prices-form>

            <el-row class="mt-3">
                <el-col class="mt-3 d-flex justify-content-end">
                    <el-button
                        type="text"
                        :loading="saveLoading"
                        :disabled="saveDisabled"
                        @click="saveDraft">Guardar Borrador
                    </el-button>
                    <el-button
                        type="primary"
                        :loading="publishLoading"
                        :disabled="publishDisabled" @click="publish">Publicar
                    </el-button>
                </el-col>
            </el-row>

        </el-col>
    </el-row>
</template>

<script>
    import {messages} from '@/constants/text-constants';
    import CakePricesForm from '@/components/CakePricesForm';
    import {setCakePrices, activateCake as publishCake} from '@/api/cake-api';
    import {mapGetters} from 'vuex';

    const SAVE_ACTION = "SAVE";
    const PUBLISH_ACTION = "PUBLISH";

    export default {
        name: "CakeshopsNewStepThree",
        components: {
            CakePricesForm
        },
        props: {
            cakeshopUuid: {
                type: String,
                required: true
            },
            cakeUuid: {
                type: String,
                required: true
            },
            initialCakeCustomizations: {
                type: Array,
                required: true
            },
            initialCakeFillings: {
                type: Array,
                required: true
            },
            initialCakeFlours: {
                type: Array,
                required: true
            },
            initialCakeToppings: {
                type: Array,
                required: true
            },
            initialCakeSweeteners: {
              type: Array,
              required: true
            },
            purchasable: {
              type: Boolean,
              default: true
            },
            title: {
                type: String,
                default: "Precios Nueva Creación"
            }
        },
        data() {
            return {
                doFormSubmit: false,
                actionRequested: null,
                loading: false
            }
        },
        created() {
            this.initialCakeCustomizations.forEach(customization => customization.price = null);
        },
        computed: {
            ...mapGetters([
                'role',
            ]),
            saveLoading() {
                return this.loading && this.actionRequested === SAVE_ACTION
            },
            publishLoading() {
                return this.loading && this.actionRequested === PUBLISH_ACTION
            },
            saveDisabled() {
                return this.loading && this.actionRequested !== SAVE_ACTION
            },
            publishDisabled() {
                return this.loading && this.actionRequested !== PUBLISH_ACTION
            }
        },
        methods: {
            requestCakeshop() {
                this.doFormSubmit = true;
                this.loading = true;
            },
            saveDraft() {
                this.actionRequested = SAVE_ACTION;
                this.requestCakeshop();
            },
            publish() {
                this.actionRequested = PUBLISH_ACTION;
                this.requestCakeshop();
            },
            doAction(cakePrices) {
                let request = {
                    "byBudget": cakePrices.byBudget,
                    "availableForPickup": cakePrices.availableForPickup,
                    "allowsPayOnDelivery": cakePrices.allowsPayOnDelivery,
                    "shippingType": cakePrices.shipping.type,
                    "shippingPrice": cakePrices.shipping.price,
                    "customizations": cakePrices.customization,
                    "fillings": cakePrices.fillings,
                    "flours": cakePrices.flours,
                    "toppings": cakePrices.toppings,
                    "sweeteners": cakePrices.sweeteners,
                    "portions": cakePrices.portions,
                    "minimumPrice": cakePrices.minimumPrice
                };

                setCakePrices(this.cakeUuid, request)
                    .then(() => {
                        if (this.actionRequested === PUBLISH_ACTION && this.role === 'ADMIN') {
                            publishCake(this.cakeUuid)
                                .then(() => {
                                    this.$emit('published');
                                })
                                .catch(() => {
                                    this.errorReturned()
                                });
                        } else {
                            this.$message.success(messages.cakeshop.saveDraft.success);
                            this.$emit('save-draft');
                        }
                    })
                    .catch(() => {
                        this.errorReturned()
                    });
            },
            errorReturned() {
                this.$message.error(messages.cakeshop.create.error);
                this.resetAction();
            },
            resetAction() {
                this.actionRequested = null;
                this.doFormSubmit = false;
                this.loading = false;
            }
        }
    }
</script>
