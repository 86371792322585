<template>
    <el-select
        v-model="cityName"
        placeholder="Seleccione ciudad"
        @change="notifyChange">
        <el-option
            v-for="city in cities"
            :key="city.uuid"
            :label="city.name"
            :value="city.uuid">
        </el-option>
    </el-select>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "CitySelector",
        props: {
            value: Object
        },
        data: function () {
            return {
                cityName: null
            }
        },
        mounted() {
            this.$store.dispatch('loadCities');
            this.cityName = this.evaluateValue(this.value);
        },
        computed: {
            ...mapGetters([
                'cities'
            ])
        },
        methods: {
            evaluateValue(value) {
                if (value && value.uuid) {
                    let city = this.findCityByUuid(value.uuid);
                    return city ? city.name : null;
                } else {
                    return null;
                }
            },
            findCityByUuid(uuid) {
                if (Array.isArray(this.cities)) {
                    return this.cities.find(city => (city.uuid === uuid));
                } else {
                    return null;
                }
            },
            notifyChange(cityUuid) {
                this.$emit('input', this.findCityByUuid(cityUuid));
            }
        },
        watch: {
            value: function () {
                this.cityName = this.evaluateValue(this.value);
            },
            cities: function () {
                this.cityName = this.evaluateValue(this.value);
            }
        }
    }
</script>

<style scoped>

</style>