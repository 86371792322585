<template>
    <el-row>
        <el-col>

            <el-row class="mb-4">
                <el-col :span="24" class="text-center">
                    <div class="view-subtitle">{{title}}</div>
                </el-col>
            </el-row>

            <cake-form :do-form-submit="doFormSubmit" @submit="doAction" @not-valid="resetAction"/>

            <el-row class="mt-3">
                <el-col class="mt-3 d-flex justify-content-end">
                    <el-button
                        type="text"
                        :loading="saveLoading"
                        :disabled="saveDisabled"
                        @click="saveDraft">Guardar Borrador
                    </el-button>
                    <el-button
                        type="primary"
                        :loading="continueLoading"
                        :disabled="continueDisabled"
                        @click="nextStep">Siguiente
                    </el-button>
                </el-col>
            </el-row>

        </el-col>
    </el-row>
</template>

<script>
    import {messages} from '@/constants/text-constants';
    import CakeForm from '@/components/CakeForm';
    import {newCake} from '@/api/cake-api';

    const SAVE_ACTION = "SAVE";
    const CONTINUE_ACTION = "CONTINUE";

    export default {
        name: "CakeshopsNewStepTwo",
        components: {
            CakeForm
        },
        props: {
            cakeshopUuid: {
                type: String,
                required: true
            },
            title: {
                type: String,
                default: "Nueva Creación"
            }
        },
        data() {
            return {
                doFormSubmit: false,
                actionRequested: null,
                loading: false
            }
        },
        computed: {
            saveLoading() {
                return this.loading && this.actionRequested === SAVE_ACTION
            },
            continueLoading() {
                return this.loading && this.actionRequested === CONTINUE_ACTION
            },
            saveDisabled() {
                return this.loading && this.actionRequested !== SAVE_ACTION
            },
            continueDisabled() {
                return this.loading && this.actionRequested !== CONTINUE_ACTION
            }
        },
        methods: {
            requestCake() {
                this.doFormSubmit = true;
                this.loading = true;
            },
            saveDraft() {
                this.actionRequested = SAVE_ACTION;
                this.requestCake();
            },
            nextStep() {
                this.actionRequested = CONTINUE_ACTION;
                this.requestCake();
            },
            doAction(cake) {
                let request = {
                    "purchasable": cake.purchasable,
                    "name": cake.name,
                    "description": cake.description,
                    "processingTime": cake.processingTime,
                    "preservation": cake.preservation,
                    "recommendations": cake.recommendations,
                    "dyes": cake.dyes,
                    "allergens": cake.allergens,
                    "allergenTraces": cake.allergenTraces,
                    "customizations": cake.customizations,
                    "fillings": cake.fillings,
                    "flours": cake.flours,
                    "toppings": cake.toppings,
                    "sweeteners": cake.sweeteners,
                    "ingredients": cake.ingredients,
                    "photos": cake.base64Images,
                    "tags": cake.tags,
                    "rank": cake.rank,
                };

                newCake(this.cakeshopUuid, request)
                    .then((response) => {
                        if (this.actionRequested === CONTINUE_ACTION) {
                            this.$emit('next-step', {
                              uuid: response.uuid,
                              purchasable: cake.purchasable,
                              customizations: cake.customizations,
                              fillings: cake.fillings,
                              flours: cake.flours,
                              toppings: cake.toppings,
                              sweeteners: cake.sweeteners
                            });
                        } else {
                            this.$message.success(messages.cakeshop.saveDraft.success);
                            this.$emit('save-draft');
                        }
                    })
                    .catch(() => {
                        this.$message.error(messages.cakeshop.create.error);
                        this.resetAction();
                    });
            },
            resetAction() {
                this.actionRequested = null;
                this.doFormSubmit = false;
                this.loading = false;
            }
        }
    }
</script>
